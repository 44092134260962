import React from "react"
import styled from "styled-components"

import { Color, Type } from "../../utilities"

const SlideStyle = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease;
  background: ${Color.white};

  ${props => (props.active ? `opacity: 1` : `opacity: 0`)};
`

const SlideBody = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const SlideBlur = styled.div`
  position: absolute;
  z-index: -1;
  background-size: cover;
  background-position: center;
  width: 110%;
  height: 110%;
  top: 0;
  left: 0;
  filter: blur(5px);
  opacity: 0.5;
  margin-top: -5%;
  margin-left: -5%;

  ${props =>
    props.image &&
    `
      background-image: url(${props.image});
    `}
`

const SlideImage = styled.div`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1;

  ${props =>
    props.image
      ? `background-image: url(${props.image})`
      : `background-color:purple`}
`

const SlideTitle = styled.div`
  color: ${Color.white};
  font-family: ${Type.header};
  font-size: 2rem;
  text-align: center;
  background: rgba(197, 32, 39, 0.8);
  position: absolute;
  z-index: 10;
  padding: 1.5rem 2rem;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  margin: auto;
  text-align: left;
`

export const Slide = ({ active, image, title }) => (
  <SlideStyle active={active}>
    <SlideBody>
      <SlideBlur image={image} />
      <SlideImage image={image} />
      {title && <SlideTitle>{title && title}</SlideTitle>}
    </SlideBody>
  </SlideStyle>
)
