import React, { useRef, useEffect } from "react"
import styled from "styled-components"

import { Color } from "../../utilities"

const PagContainer = styled.div`
  width: 100%;
  overflow: hidden;
`

const PagFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const Item = styled.div`
  height: 90px;
  width: 90px;
  border: 5px solid transparent;
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: ${Color.white};
  position: relative;
  flex-shrink: 0;
  flex-basis: 90px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    ${props =>
      props.image &&
      `
      background-image: url(${props.image});
    `}
  }

  ${props =>
    props.active &&
    `
      border-color: ${Color.red};
    `}
`

export const Pagination = ({ slides, active, handleClick }) => {
  const ref = useRef(null)
  useEffect(() => {
    const clientWidth = ref.current.clientWidth
  }, [active])

  return (
    <PagContainer>
      <PagFlex ref={ref}>
        {slides.map((slide, ix) => (
          <Item
            image={slide.node.featured_media?.source_url}
            active={ix === active}
            onClick={() => handleClick({ current: ix })}
          />
        ))}
      </PagFlex>
    </PagContainer>
  )
}
