import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import { Slide } from "../Slide"
import { Pagination } from "../Pagination"
import { Media } from "../../utilities"

const SliderStyle = styled.div`
  display: block;
  top: 20px;
  position: relative;
  height: 700px;
  margin: 0 auto;
  overflow: hidden;
  justify-content: center;

  ${Media.below.desktop`
    height: 450px;
  `}
`

const SlideContainer = styled.div``

const SlideDescription = styled.div`
  display: block;
  margin: 1rem;
  margin-top: 3rem;
`

const getDescription = ({ slides, active }) => {
  const item = slides.find((slide, ix) =>
    ix === active && slide?.node?.content ? slide.node.content : null
  )
  return item?.node ? item.node.content : null
}

const interval = 7500

const handleClick = ({ stopSlider, setActive, slider, current }) => {
  stopSlider()
  setActive(current)
  setTimeout(() => slider(), interval)
}

export const WorkSlider = ({ slides = [] }) => {
  const [active, setActive] = useState(0)
  const intervalRef = useRef(null)

  const slider = () => {
    intervalRef.current = setInterval(
      () => setActive(a => (a + 1) % slides.length),
      interval
    )
  }

  const stopSlider = () => {
    clearInterval(intervalRef.current)
    intervalRef.current = null
  }

  useEffect(() => {
    slider()
    return () => clearInterval(intervalRef.current)
  }, [])

  return (
    <SlideContainer>
      <Pagination
        slides={slides}
        active={active}
        handleClick={({ current }) =>
          handleClick({ current, slider, stopSlider, setActive })
        }
      />
      <SliderStyle>
        {slides.map((slide, ix) => (
          <Slide
            image={slide.node.featured_media?.source_url}
            title={slide.node.title}
            active={ix === active}
          />
        ))}
      </SliderStyle>
      <SlideDescription
        dangerouslySetInnerHTML={{ __html: getDescription({ slides, active }) }}
      ></SlideDescription>
    </SlideContainer>
  )
}
